import { registerApplication, start, LifeCycles } from "single-spa";

// registerApplication({
//   name: "@single-spa/welcome",
//   app: () =>
//     System.import<LifeCycles>(
//       "https://unpkg.com/single-spa-welcome/dist/single-spa-welcome.js"
//     ),
//   activeWhen: ["/"],
// });

registerApplication({
  name: "@bee2pay/hotel-reservation-frontend",
  app: () =>
    System.import<LifeCycles>("@bee2pay/hotel-reservation-frontend"),
  activeWhen: ["/"],
});

// registerApplication({
//   name: "@bee2pay/navbar",
//   app: () => System.import("@bee2pay/navbar"),
//   activeWhen: ["/"]
// });

start({
  urlRerouteOnly: true,
});
